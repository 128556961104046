/* Contenitore principale */
.documentDetail_MainContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff; /* Sfondo bianco per un aspetto pulito */
  font-family: 'Roboto', sans-serif; /* Font moderno */
}

/* Contenitore del contenuto */
.documentDetail_ContentContainer {
  flex: 1;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Contenuto principale */
.documentDetail_MainContent {
  width: 100%;
  max-width: 1200px;
  background-color: #fafafa; /* Sfondo leggermente off-white */
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Ombra sottile per profondità */
  padding: 30px;
}

/* Box di caricamento */
.documentDetail_LoadingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

/* Wrapper per iframe e informazioni */
.documentDetail_DisplayWrapper {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
}

/* Contenitore dell'iframe */
.documentDetail_IframeContainer {
  flex: 2;
  height: 600px;
  background-color: #fff; /* Sfondo bianco per l'iframe */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

/* Stile dell'iframe */
.documentDetail_Iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Contenitore delle informazioni */
.documentDetail_InfoContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9; /* Sfondo leggero per distinguere le info */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

/* Titolo del documento */
.documentDetail_InfoContainer h5 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

/* Sottotitolo */
.documentDetail_InfoContainer p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}

/* Link e pulsanti */
.documentDetail_InfoContainer a {
  color: #1976d2;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.documentDetail_InfoContainer a:hover {
  color: #115293;
  text-decoration: underline;
}

/* Pulsante di download */
.documentDetail_InfoContainer .downloadButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #1976d2;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease;
  text-decoration: none;
  margin-top: 10px;
}

.documentDetail_InfoContainer .downloadButton:hover {
  background-color: #115293;
}

/* Media Queries per dispositivi mobili */
@media (max-width: 768px) {

  .documentDetail_ContentContainer {
    flex: 1;
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .documentDetail_MainContent {
    padding: 20px;
  }

  .documentDetail_DisplayWrapper {
    flex-direction: column;
    gap: 20px;
    align-items: stretch;
  }

  .documentDetail_IframeContainer {
    width: 100%; /* Usa tutta la larghezza disponibile */
    height: 70vh; /* Usa il 70% dell'altezza dello schermo */
    max-height: 600px; /* Limita l'altezza massima per dispositivi con schermi più grandi */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .documentDetail_Iframe {
    width: 100%;
    height: 100%; 
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Aggiunge un leggero ombreggiatura */
  }

  .documentDetail_InfoContainer {
    padding: 15px;
  }

  .documentDetail_InfoContainer h5 {
    font-size: 1.25rem;
  }

  .documentDetail_InfoContainer p {
    font-size: 0.95rem;
  }

  .documentDetail_InfoContainer .downloadButton {
    width: 100%;
    text-align: center;
  }
}