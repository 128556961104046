.tutorial-row-video {
  flex: 0 0 70%;
  display: flex;
  justify-content: center;
  /* Assicuriamoci che l'altezza non venga forzata altrove */
  overflow: visible;
}

.video-container {
  position: relative;
  width: 100%;
  /* Se preferisci non limitarlo su desktop, commenta la max-width */
  /* max-width: 1200px; */
  aspect-ratio: 16 / 9;
  border-radius: 1.25rem;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Media query per mobile */
@media (max-width: 768px) {
  .tutorial-row {
    flex-direction: column; /* Metti testo e video uno sotto l'altro */
  }

  .tutorial-row-text, .tutorial-row-video {
    flex: auto;
    width: 100%;
    max-width: 100%;
  }

  /* Se l'aspect-ratio crea problemi sui device mobili, togliamolo */
  .video-container {
    aspect-ratio: auto;
    min-height: 220px; /* Spazio minimo per vedere il video */
  }
}
