/* src/components/styles/Resources.css */

.resourcesPage_MainContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f5f5f5; /* Sfondo chiaro per contrastare il contenuto */
}

.thumbnailImage_UniqueImg {
  width: 100%;
  height: 100%;
  object-fit: cover; /* L'immagine copre l'intero contenitore mantenendo l'aspect ratio */
  object-position: top; /* L'immagine è posizionata in alto */
  border-radius: 8px; /* Mantieni la consistenza con il box */
  transition: transform 0.3s ease;
}

.documentCategory_UniqueBox:hover .thumbnailImage_UniqueImg {
  transform: scale(1.05); /* Aggiungi un leggero ingrandimento al passaggio del mouse */
}

.resourcesPage_ContentContainer {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center; /* Centra orizzontalmente */
  align-items: center; /* Centra verticalmente */
}

.resourcesPage_MainContent {
  width: 100%;
  max-width: 1200px; /* Limita la larghezza massima */
}

.resourcesHeader_UniqueSection {
  text-align: center;
  margin-bottom: 40px;
}

.resourcesHeader_UniqueSection h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.resourcesHeader_UniqueSection p {
  font-size: 1.2rem;
  color: #555;
}

.resourcesList_UniqueSection {
  width: 100%;
}

.resourcesList_LoadingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.categoriesContainer_UniqueWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoriesGrid_UniqueDisplay {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Aumenta la larghezza minima delle colonne */
  gap: 20px;
  width: 100%;
  max-width: 1000px; /* Aumenta la larghezza massima per una migliore distribuzione */
}

.categoryItem_UniqueBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 250px; /* Aumenta l'altezza per i div rossi */
}

.documentCategory_UniqueBox {
  width: 100%;
  height: 200px; /* Adeguare l'altezza secondo necessità */
  background: #DF4634;
  border-radius: 8px; /* Dimezza la curvatura da 15px a 8px */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden; /* Nasconde eventuali overflow dell'iframe */
  transition: transform 0.2s, background-color 0.2s;
}

.documentCategory_UniqueBox:hover {
  transform: scale(1.05);
}

.categoryTitle_UniqueText {
  font-size: 1rem;
  text-align: center;
  margin-top: 10px;
  height: 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pdfPreview_UniqueIframe {
  width: 100%;
  height: 100%;
  border: none; /* Rimuove il bordo dell'iframe */
}

/* Nuove Classi Aggiunte */

.categoryTitleContainer {
  display: flex;
  align-items: center; /* Allinea la stella e il titolo verticalmente */
  justify-content: center; /* Mantiene il titolo centrato */
  margin-bottom: 0px; /* Spazio tra il titolo e l'immagine */
  gap: 0.2rem;
  text-align: center; /* Centra il testo del titolo */
}

.premiumIcon {
  width: 1rem; /* Dimensione della stella */
  height: 1rem;
  margin-bottom: 1.4rem;
}

/* Media Queries */

@media (max-width: 600px) {
  .categoriesGrid_UniqueDisplay {
    grid-template-columns: repeat(2, 1fr); /* Imposta esattamente due colonne */
    max-width: 100%; /* Rimuove il limite di larghezza massima per adattarsi meglio allo schermo */
    gap: 15px; /* Eventuale riduzione dello spazio tra le colonne per adattarsi meglio */
  }

  .categoryItem_UniqueBox {
    height: auto; /* Consente alle singole voci di adattarsi all'altezza del contenuto */
  }

  .documentCategory_UniqueBox {
    height: 150px; /* Riduce l'altezza per adattarsi meglio alle dimensioni più piccole */
  }

  .categoryTitle_UniqueText {
    font-size: 0.9rem; /* Riduce la dimensione del testo per migliorare la leggibilità su schermi piccoli */
    height: auto; /* Consente al testo di espandersi se necessario */
  }

  .premiumIcon {
    width: 1rem; /* Dimensione della stella */
    height: 1rem;
    margin-bottom: 0rem;
  }
}